import React from 'react'
import styles from './ClientsBlock.module.css'

const ClientsBlock = (props) => {
  return (
    <ul className="rsm-clients-ul">
      {props.clients.map((client, index) => {
        return <li className={client.title ? "clients title" : "clients client-tile"} key={index}><div>{client.name}</div></li>
      })}
    </ul>
  )
}

export default ClientsBlock