import React from 'react'
import styles from './Spotify.module.css'

const Spotify = (props) => {
  return (
    <div className={styles.container}>
      <iframe title="spotify" width="100%" height="430" frameBorder="no" src={props.spotifyLink} allowtransparency="true" allow="encrypted-media"></iframe>
    </div>
  )
}

export default Spotify