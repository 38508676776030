import React from 'react'
import styles from './Soundcloud.module.css'

const Soundcloud = (props) => {
  return (
    <div className={styles.container}>
      <iframe title="soundcloud" width="100%" height={props.large ? 350 : 150} scrolling="no" frameBorder="no" src={props.scLink}></iframe>
    </div>
  )
}

export default Soundcloud