import React from 'react'
import LegacyContainer from '../components/legacy-container'
import PageTitle from '../components/modular/PageTitle/PageTitle'
import ClientsBlock from '../components/modular/ClientsBlock/ClientsBlock'
import FullWidthImage from '../components/modular/FullWidthImage/FullWidthImage'
import ParagraphText from '../components/modular/ParagraphText/ParagraphText'
import Testimonials from '../components/modular/Testimonials/Testimonials'
import SEO from '../components/SEO'
import Soundcloud from '../components/modular/Soundcloud/Soundcloud'
import Spotify from '../components/modular/Spotify/Spotify'

const ModularPage = ({ pageContext }) => {
  if (!pageContext.frontmatter) {
    return (<div>Something went wrong...</div>)
  }

  const pageTitle = pageContext.frontmatter.heading;
  const sections = pageContext.frontmatter.sections;

  return (
    <LegacyContainer>
      <SEO pageTitle={pageTitle} />
      <div className="content-container">
        <div className="content">
          {sections.map((section, index) => renderSection(section, index))}
        </div>
      </div>
    </LegacyContainer>
  )
}

const renderSection = (section, index) => {
  if (section.type === 'heading') {
    return (
      <PageTitle title={section.heading} key={index} />
    );
  } else if (section.type === 'clients') {
    return (
      <ClientsBlock clients={section.clients} key={index} />
    )
  } else if (section.type === 'headlineImage') {
    return (
      <FullWidthImage image={section.headlineImg} key={index} />
    )
  } else if (section.type === 'text') {
    return (
      <ParagraphText html={`<p>${section.textBody}</p>`} key={index} />
    )
  } else if (section.type === 'testimonials') {
    return (
      <Testimonials reviews={section.testimonials} key={index} />
    )
  } else if (section.type === 'soundcloud') {
    return (
      <Soundcloud scLink={section.soundcloudUrl} large={section.large} key={index} />
    )
  } else if (section.type === 'spotify') {
    return (
      <Spotify spotifyLink={section.spotifyUrl} key={index} />
    )
  } else {
    return (
      <div style={{ color: '#fff' }} key={index}>
        <pre>{JSON.stringify(section, undefined, 2)}</pre>
      </div>
    )
  }
}

export default ModularPage